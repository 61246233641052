import validate from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.13.9_eslint@9.21.0_jiti@2.4.2__ioredis@5._mtrmjxev3ygfue5d3wtpjij4yi/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.13.9_eslint@9.21.0_jiti@2.4.2__ioredis@5._mtrmjxev3ygfue5d3wtpjij4yi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/middleware/auth.ts"),
  cgu: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/middleware/cgu.ts"),
  "skip-auth": () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/middleware/skip-auth.ts")
}