import { default as emails5iAkbBnylSMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/emails.vue?macro=true";
import { default as integrationsOw3do3n2pJMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/integrations.vue?macro=true";
import { default as overviewDn0Xru6FiNMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/overview.vue?macro=true";
import { default as indexE1E4V7P54EMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsTtnmGxxcUkMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as index1PPGRePWimMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/index.vue?macro=true";
import { default as indexDryhSDCrGfMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/[id]/index.vue?macro=true";
import { default as add6HeCm0nxXLMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/add.vue?macro=true";
import { default as indexj8MoYVZI3OMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/index.vue?macro=true";
import { default as adminRr8w01XoaRMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin.vue?macro=true";
import { default as indexoQ0jkBYhOfMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/archive/index.vue?macro=true";
import { default as entourage4bFbeNZCwJMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsU1DaztbW0TMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthAckyJygW3aMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoin37jCt5dSMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationyJ4GKYrKP6Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdlgmMAWvMZQMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printFp2denCqHIMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexN5XLgKdALDMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/index.vue?macro=true";
import { default as editzPXs5G4G6qMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as edittq4Jvir5q6Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editl4zzLshBK6Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addjvAtbSYp0mMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editC7z7pH4TXIMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as add8y1S9idjUIMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as edittOzUc59Qh2Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as adddPPzW6szECMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editTDNsbSOYv0Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as index48UJh5t43XMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/index.vue?macro=true";
import { default as addQi0qyRjaINMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/add.vue?macro=true";
import { default as historyGrXmZbgTYdMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/history.vue?macro=true";
import { default as printv0dtsLHZwjMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as index_46client8K0uyi8ZmIMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/integrations/rdvsp/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.13.9_eslint@9.21.0_jiti@2.4.2__ioredis@5._mtrmjxev3ygfue5d3wtpjij4yi/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as overviewEUWYOQ7Ld4Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/overview.vue?macro=true";
import { default as partnerstgkLOJsfjWMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/partners.vue?macro=true";
import { default as statsTjRmRKfa6iMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/stats.vue?macro=true";
import { default as indexZ8A4xNAs12Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/structure/index.vue?macro=true";
import { default as subjectsyTs5yY6VaTMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/structure/subjects.vue?macro=true";
import { default as usersjwaT2QSH46Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/users.vue?macro=true";
import { default as appet4L1qSuM1Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app.vue?macro=true";
import { default as adminKik4wbzQSKMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/admin.vue?macro=true";
import { default as loginp05uWcMj2zMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/login.vue?macro=true";
import { default as logout5NDarvN5hyMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/logout.vue?macro=true";
import { default as validate_45tokens75YSOcCMDMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/validate-token.vue?macro=true";
import { default as managerDwmj5qrjEzMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu/manager.vue?macro=true";
import { default as user2dMhZZEBQMMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu/user.vue?macro=true";
import { default as cguqawNVdeTK4Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitehAEH3qmCriMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountys5oGVXkEaMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokenaq4haLmxTUMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45userL9yE0zNyEOMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/unknown-user.vue?macro=true";
import { default as indexrOUYsv9tbhMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/index.vue?macro=true";
import { default as mentions_45legalesZOP6EeilEbMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteGuTY84lpltMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/politique-confidentialite.vue?macro=true";
import { default as registertQSfy1h2iHMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/register.vue?macro=true";
import { default as statistiquesUXqnxLt0vYMeta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/statistiques.vue?macro=true";
import { default as storiesxw30Xtsjy2Meta } from "/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminRr8w01XoaRMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/integrations.vue")
  },
  {
    name: overviewDn0Xru6FiNMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewDn0Xru6FiNMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appet4L1qSuM1Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app.vue"),
    children: [
  {
    name: indexoQ0jkBYhOfMeta?.name ?? "app-beneficiaries-id-archive",
    path: "beneficiaries/:id()/archive",
    meta: indexoQ0jkBYhOfMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/archive/index.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupationyJ4GKYrKP6Meta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationyJ4GKYrKP6Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printFp2denCqHIMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexN5XLgKdALDMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexN5XLgKdALDMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/beneficiaries/index.vue")
  },
  {
    name: editzPXs5G4G6qMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editzPXs5G4G6qMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: edittq4Jvir5q6Meta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: edittq4Jvir5q6Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editTDNsbSOYv0Meta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editTDNsbSOYv0Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printv0dtsLHZwjMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: "app-integrations-rdvsp",
    path: "integrations/rdvsp",
    meta: index_46client8K0uyi8ZmIMeta || {},
    component: () => createClientPage(() => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/integrations/rdvsp/index.client.vue"))
  },
  {
    name: overviewEUWYOQ7Ld4Meta?.name ?? "app-overview",
    path: "overview",
    meta: overviewEUWYOQ7Ld4Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminKik4wbzQSKMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginp05uWcMj2zMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logout5NDarvN5hyMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokens75YSOcCMDMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguqawNVdeTK4Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitehAEH3qmCriMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountys5oGVXkEaMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokenaq4haLmxTUMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userL9yE0zNyEOMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexrOUYsv9tbhMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesZOP6EeilEbMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteGuTY84lpltMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registertQSfy1h2iHMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesUXqnxLt0vYMeta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesxw30Xtsjy2Meta || {},
    component: () => import("/build/0d32c4be-082c-48c2-8c2c-3beb9aa412e5/pages/stories.vue")
  }
]